import { render, staticRenderFns } from "./ChemOleMiss116PL1Q1.vue?vue&type=template&id=50b7b16a&scoped=true&"
import script from "./ChemOleMiss116PL1Q1.vue?vue&type=script&lang=ts&"
export * from "./ChemOleMiss116PL1Q1.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50b7b16a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VFileInput,VForm,VRow})
